import { cva, type VariantProps } from 'class-variance-authority'

export { default as Button } from './Button.vue'

export const buttonVariants = cva(
	[
		'relative isolate inline-flex items-center justify-center gap-x-2 rounded-full text-base/6 font-bold',

		// Focus
		'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple',

		// Disabled
		'disabled:opacity-50',
	],
	{
		variants: {
			variant: {
				default: 'border-2 border-purple bg-purple text-white shadow hover:border-purple/80 hover:bg-purple/80 focus-visible:outline-purple',
				white: 'border-2 border-white bg-white text-purple hover:bg-transparent hover:text-white focus-visible:outline-white',
				outline: 'border-2 border-purple bg-white text-purple shadow hover:border-purple/80 hover:bg-purple/80 hover:text-white focus-visible:outline-purple',
				secondary: 'bg-gray-100 text-gray-900 shadow-sm hover:bg-gray-100/70 focus-visible:outline-gray-900',
				ghost: 'hover:bg-gray-100 hover:text-gray-900 focus-visible:outline-gray-900',
			},
			size: {
				sm: 'px-4 py-2.5 text-sm/none',
				default: 'px-4.5 py-2.5 text-base/none md:py-3',
				lg: 'px-4.5 py-3 text-base/none font-bold sm:px-5 sm:py-3.5 sm:text-lg/none',
				icon: 'h-9 w-9',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
)

export type ButtonVariants = VariantProps<typeof buttonVariants>
