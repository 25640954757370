import { useSeoMeta } from '@unhead/vue'
import { MaybeRef } from 'vue'

export function useSeo({
	title,
	description,
}: {
	title?: MaybeRef<string>,
	description?: MaybeRef<string>,
}): void {
	useSeoMeta({
		title,
		ogTitle: title,
		twitterTitle: title,
		description,
		ogDescription: description,
		twitterDescription: description,
	})
}
