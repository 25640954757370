<script setup lang="ts">
	import Footer from '@/components/marketing/Footer.vue'
	import Header from '@/components/marketing/Header.vue'
	import { useSeo } from '@/composables/useSeo'

	useSeo({
		title: 'TravlFi | Unlimited Pay As You Go Mobile Internet',
		description: 'Internet that travels with you, no commitments, cancel anytime.',
	})
</script>

<template>
	<div class="relative flex min-h-screen flex-col bg-gray-50 text-gray-700">
		<Header />

		<main class="grow">
			<slot />
		</main>

		<Footer />
	</div>
</template>
